<template>
  <div class="contents-wrap">
    <div
      class="contents__items -left"
      v-if="withNav"
    >
      <navigation />
    </div>

    <div
      v-if="withNav"
      class="contents__items -right"
    >
      <router-view />
    </div>

    <router-view v-else />
  </div>
</template>

<script>
//= jquery
import $ from 'jquery'

//= translation
import { common } from '@/assets/js/translations/Common'

//= mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'

//= components
import Navigation from '@/components/layout/navigation/Navigation'

export default {
  name: 'main-layout',

  components: {
    Navigation
  },

  mixins: [
    detectDevice
  ],

  props: {
    withNav: {
      type: [ Boolean ],
      required: true
    }
  },

  data () {
    return {
      common
    }
  }
}
</script>
